<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    >
      <dark-Toggler class="d-none d-lg-block" />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <Locale />
      <buttonChangeCustomer :class="'ml-2 mr-2'" v-if="user.rol_id != 3" />
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p
              class="user-name font-weight-bolder mb-0"
              v-if="user.rol_id != 3"
            >
              {{ user.usuario }}
            </p>
            <p class="user-name font-weight-bolder mb-0" v-else>
              {{ client.NOMBRE }}
            </p>
            <span class="user-status">{{ user.rol_nombre }}</span>
          </div>
        </template>

        <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>{{ $i18n.messages.CerrarSesion }}</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";
import buttonChangeCustomer from "@core/layouts/components/ButtonChangeCustomer.vue";
import Locale from "@core/layouts/components/app-navbar/components/Locale.vue";
import { mapMutations, mapGetters } from "vuex";
export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    buttonChangeCustomer,
    Locale,
    DarkToggler,
  },
  methods: {
    ...mapMutations({
      logout: "auth/logout",
    }),
  },
  computed: {
    ...mapGetters({
      user: "auth/getUser",
      client: "customers/getCurrentCustomer",
    }),
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
};
</script>
